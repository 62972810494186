body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.hidden {
	display: none !important;
}

#divPWAInstall {
	position: fixed;
	bottom: 10px;
	right: 10px;
	display: flex;
	z-index: 9999;
}

	#divPWAInstall button {
		background-color: #6b19f9;
		border: 1px solid black;
		color: white;
		font-size: 1em;
		font-weight: bold;
		padding: 0.75em;
	}
