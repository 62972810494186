.horizontal-slider {
	width: 100%;
	max-width: 500px;
	height: 100vh;
	margin: auto;
}

.filterslide-thumb {
	cursor: pointer;
	position: absolute;
	z-index: 100;
	background: #ffffff;
	border: 5px solid #3774ff;
	border-radius: 100%;
	display: block;
	box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.filterslide-thumb.active {
	background-color: grey;
}

.filterslide-track {
	position: relative;
	background: #ddd;
}

.filterslide-track.filterslide-track-0 {
	background: #83a9ff;
}

.horizontal-slider .filterslide-track {
	top: 20px;
	height: 2px;
}

.horizontal-slider .filterslide-thumb {
	top: 12px;
	width: 10px;
	outline: none;
	height: 10px;
	line-height: 38px;
}
